import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(
  ({layout, fontSizes, breakpoints, spacing, palette}) => ({
    heading: {
      textAlign: 'center',
      '& > p': {marginTop: 10, justifyContent: 'center'},
      paddingBottom: spacing(5.38),
      [breakpoints.down('lg')]: {
        padding: `0 ${spacing(4)}px ${spacing(5)}px ${spacing(4)}px`,
        '& > p': {marginBottom: 5},
      },
    },
    slideWrapper: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: spacing(5),
      [breakpoints.down('sm')]: {
        flexDirection: 'column',
        height: '105vh',
        '& .slider': {
          height: '100% !important',
        },
        '& .slider-frame': {
          width: '100% !important',
        },
        '& .slider-list': {
          height: '100% !important',
        },
        '& .slider-slide': {
          height: '100% !important',
        },
        '& *': {
          display: 'block',
        },
      },
      '& h3, p:last-of-type': {
        color: '#333333',
      },
    },
    slideRight: {
      width: '40%',
      marginLeft: layout.homeInsights.text.marginLeft,
      alignItems: 'start',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'start!important',
      minHeight: layout.homeInsights.text.minHeight,
      '& > p:first-of-type': {
        marginBottom: spacing(1.25),
      },
      '& > h3': {
        marginTop: spacing(1),
      },
      [breakpoints.down('sm')]: {
        width: '100%',
        minHeight: 'inherit',
        marginLeft: 0,
        paddingRight: 0,
        '& > p:first-of-type': {
          marginBottom: spacing(1.25),
        },
        '& > p': {
          marginBottom: spacing(1.25),
        },
        '& > h3': {
          marginTop: 0,
        },
      },
    },
    slideLeft: {
      width: '60%',
      maxWidth: layout.homeInsights.image.maxWidth,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      borderRadius: spacing(1.1, 5),
      overflow: 'hidden',
      '& .slider': {
        height: '100%!important',
      },
      '& .slider-frame': {
        width: '100%!important',
        borderRadius: spacing(1.1, 5),
      },
      '& .slider-list': {
        height: '100%!important',
      },
      '& .slider-slide': {
        height: '40vh !important',
      },
    },
    slideLeftMobile: {
      display: 'block',
      width: '100%',
      margin: `0 auto ${spacing(2.5)}px auto`,
      maxWidth: layout.homeInsights.image.maxWidthMobile,
      height: '40vh',
      overflow: 'hidden',
      '& img': {
        height: '40vh !important',
        width: 'auto',
        borderRadius: spacing(1.1, 5),
      },
      '& .slider': {
        height: '100%!important',
      },
      '& .slider-frame': {
        width: '100%!important',
        borderRadius: spacing(1.1, 5),
      },
      '& .slider-list': {
        height: '100%!important',
      },
    },
    slideDescription: {
      fontFamily: 'CamptonLight',
      fontSize: '1.13em',
      minHeight: 80,
      [breakpoints.down('sm')]: {
        minHeight: 'inherit',
        marginTop: spacing(1.2),
        marginBottom: `${spacing(2)}px !important`,
      },
    },
    slideNavigation: {
      marginTop: spacing(4),
      display: 'flex',
      justifyContent: 'center',
      opacity: '0.5',
      '& > *': {
        height: 75,
        width: 75,
        border: `1px solid ${palette.background.gray}`,
      },
    },
    dotsWrapper: {
      '& > div > a': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    dotsNavigation: {
      margin: 'auto',
      '& .MuiPaper-root': {background: palette.primary.main},
      [breakpoints.down('sm')]: {
        margin: `0 auto ${spacing(4)}px auto`,
      },
    },
    date: {
      fontSize: fontSizes.medium,
      color: palette.text.grey,
      fontFamily: 'CamptonMedium',
      margin: `0 auto ${spacing(0.6)}px 0`,
    },
    rightArrow: {
      marginLeft: spacing(4),
      '& > *': {
        transform: 'rotate(180deg)',
      },
    },
    dotsContainer: {
      display: 'flex',
      justifyContent: 'center',
      [breakpoints.down('sm')]: {
        marginBottom: spacing(6),
      },
    },
    dots: {
      borderRadius: '50%',
      width: spacing(1),
      height: spacing(1),
      margin: spacing(0, 1.25, 0, 0),
      backgroundColor: palette.background.gray,
      cursor: 'pointer',
      transitionProperty: 'transform',
      transitionDuration: '0.5s',
    },
    activeDots: {
      backgroundColor: palette.primary.main,
      transform: 'scale(1.3)',
    },
    buttonContainer: {
      paddingTop: spacing(5),
    },
  }),
)

export default useStyles
