import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({palette, breakpoints, fontSizes, spacing}) => ({
  joinUs: {
    padding: `${spacing(14.38)}px 0 ${spacing(20)}px 0`,
    background: palette.background.greyLight,
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'row nowrap',
    position: 'relative',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: 4,
    },
  },
  submain: {
    width: '50%',
    '& > p': {
      margin: `${spacing(1.5)}px 0 0 0`,
    },
    [breakpoints.down('sm')]: {
      width: '100%',
    },
    [breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  left: {
    paddingRight: spacing(5),
    [breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
  buttonLabel: {
    marginRight: 0,
  },
  buttonArrow: {
    transform: 'rotate(225deg)',
  },
  topLeft: {
    '& p:first-of-type': {
      marginTop: 0,
      marginBottom: spacing(1.3),
    },
    '& p:nth-last-of-type(1)': {
      marginTop: spacing(2),
      marginBottom: spacing(2.2),
    },
    [breakpoints.down('sm')]: {
      textAlign: 'center',
      margin: '0 auto',
      '& p:nth-last-of-type(1)': {
        marginTop: spacing(1),
        marginBottom: spacing(3),
      },
    },
  },
  maxWidth: {
    [breakpoints.down('sm')]: {
      maxWidth: 700,
    },
  },
  wideAvatar: {
    '& div': {
      borderRadius: `${spacing(1)}px ${spacing(4.38)}px`,
      transform: 'translateZ(0)',
      width: ' 100%',
    },
  },

  /* Testimonial */
  bottomLeft: {
    marginTop: 70,
    display: 'flex',
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      margin: '70px auto 0 auto',
      position: 'relative',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: -35,
        left: 0,
        width: '100%',
        height: 1,
        backgroundColor: palette.background.border,
        opacity: '0.2',
      },
    },
  },
  avatar: {
    width: ' 100%',
    maxWidth: 184,
    borderRadius: `${spacing(1)}px ${spacing(2)}px`,
    transform: 'translateZ(0)',
    overflow: 'hidden',
  },
  bottomLeftText: {
    [breakpoints.up('md')]: {
      marginLeft: 20,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    [breakpoints.down('sm')]: {
      marginTop: spacing(2),
    },
  },
  underTitle: {
    fontSize: fontSizes.medium,
    color: palette.text.grey,
    fontFamily: 'CamptonMedium',
    margin: '5px 0 5px  0',
  },
  link: {
    all: 'inherit',
    color: palette.primary.main,
    textDecoration: 'none',
    fontFamily: 'CamptonSemiBold',
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    fontSize: '1.125em',
    cursor: 'pointer',
    [breakpoints.down('sm')]: {
      justifyContent: 'center',
      margin: `0 0 ${spacing(5)}px 0`,
    },
  },
  linkArrow: {
    transform: 'rotate(225deg)',
    margin: `${spacing(0.25)}px 0 0 ${spacing(1.25)}px`,
  },
  image: {
    opacity: 1,
    transition: '.5s ease',
    backfaceVisibility: 'hidden',
    width: '100%',
    position: 'relative',
    marginBottom: spacing(5.75),
    '& div': {
      borderRadius: `${spacing(1)}px ${spacing(4.38)}px`,
      transform: 'translateZ(0)',
    },
  },
  container: {
    position: 'relative',
    '&:hover': {
      '& $image': {
        filter: 'blur(1px) brightness(30%) opacity(70%)',
      },
      '& $layer': {
        opacity: 1,
      },
    },
  },
  layer: {
    transition: '.5s ease',
    opacity: 0,
    position: 'absolute',
    top: '31%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '-ms-transform': 'translate(-50%, -50%)',
    textAlign: 'center',
    width: '100%',
    [breakpoints.down('sm')]: {
      top: '22%',
    },
  },
  logo: {
    backgroundColor: 'transparent',
    width: '35%',
  },
}))

export default useStyles
