import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useIsLargeScreen = () => {
  const {breakpoints} = useTheme()

  return useMediaQuery(breakpoints.up('lg'))
}

export default useIsLargeScreen
