import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({breakpoints, fontSizes, palette, spacing}) => ({
  miniPicturesWrapper: {
    display: 'flex',
    gap: 40,
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    '& > div': {
      width: 'calc(100% / 3)',
    },
    [breakpoints.down('sm')]: {
      marginTop: spacing(5),
      flexDirection: 'column',
      '& > div': {
        width: '100%',
      },
    },
  },
  miniPicturesWrapperImage: {
    alignItems: 'flex-start',
  },
  tooltips: {
    backgroundColor: 'white',
    color: 'black',
    whiteSpace: 'pre',
    '& a': {
      color: 'black',
    },
  },
  cards: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    whiteSpace: 'break-spaces',
    [breakpoints.down('sm')]: {
      marginBottom: spacing(3),
    },
    '& h3': {
      height: 50,
    },
  },
  picturesWrapper: {
    maxWidth: 180,
    height: 200,
    paddingBottom: spacing(3),
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
  miniPicturesTitle: {
    textAlign: 'center',
    fontSize: fontSizes.innerTitle,
    margin: spacing(1.75, 'auto'),
  },
  dotsContainer: {
    display: 'flex',
    justifyContent: 'center',
    [breakpoints.down('sm')]: {
      margin: spacing(3, 0),
    },
  },
  dots: {
    borderRadius: '50%',
    width: spacing(1),
    height: spacing(1),
    margin: spacing(0, 1.25, 0, 0),
    backgroundColor: palette.background.gray,
    cursor: 'pointer',
    transitionProperty: 'transform',
    transitionDuration: '0.5s',
  },
  activeDots: {
    backgroundColor: palette.primary.main,
    transform: 'scale(1.3)',
  },
}))

export default useStyles
