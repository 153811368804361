import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({breakpoints, palette, spacing}) => ({
  heading: {
    paddingTop: 115,
    width: '100%',
    textAlign: 'center',
  },
  cardWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& > a': {
      marginTop: spacing(12.5),
      [breakpoints.down('sm')]: {
        marginTop: spacing(5),
      },
    },
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    width: '90%',
    marginTop: spacing(12.5),
    justifyContent: 'center',
    [breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column-reverse',
      marginTop: spacing(5),
    },
  },
  cardRevert: {
    flexDirection: 'row-reverse',
    [breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  cardText: {
    width: '40%',
    padding: '0 5%',
    '& > p': {margin: `${spacing(2)}px 0`},
    '& > a': {marginTop: spacing(5)},
    [breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
      marginTop: spacing(2.5),
      '& > *': {
        textAlign: 'center',
      },
    },
  },
  cardImage: {
    width: '50%',
    maxWidth: 550,
    position: 'relative',
    '& div': {
      borderRadius: spacing(1, 4.38),
      transform: 'translateZ(0)',
    },
    [breakpoints.down('sm')]: {
      width: '100%',
    },
    '&:after': {
      position: 'absolute',
      zIndex: 9,
      content: '" "',
      transition: 'all 0.25s',
      left: 0,
      backgroundColor: palette.background.contrastGrey,
      borderRadius: spacing(1, 4.38),
      transform: 'translateZ(0)',
      top: 0,
      width: 0,
      height: '100%',
    },
  },
  wipe: {
    '&:after': {
      width: '100%',
      height: '100%',
    },
  },
  t1: {
    '&:after': {
      transitionDelay: '0s',
    },
  },
  t2: {
    '&:after': {
      transitionDelay: '.5s',
    },
  },
  t3: {
    '&:after': {
      transitionDelay: '1s',
    },
  },
}))

export default useStyles
