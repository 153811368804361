import {ParallaxProvider} from 'react-scroll-parallax'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import React from 'react'

import {manifestoPropTypes} from 'helpers/propTypes'
import {slugSwitch} from 'helpers/utils'
import Description from 'components/UI/Description'
import Dots from 'material-ui-dots'
import Heading from 'components/UI/Heading'
import MiniPictures from 'components/UI/MiniPictures'
import Section from 'components/UI/Section'
import Title from 'components/UI/Title'

import useStyles from './styles'

const Manifesto = ({data}) => {
  const classes = useStyles()
  const Decoration = loadable(() => import('components/UI/Decoration'))
  const Decorations = loadable(() => import('components/UI/Decorations'))
  const ParallaxVideo = loadable(
    () => pMinDelay(import('./ParallaxVideo'), 2000),
    {
      fallback: <div className={classes.parallaxLazyLoading} />,
    },
  )
  const RoundButton = loadable(() => import('components/UI/RoundButton'))

  const {
    shortDescription,
    parallaxMediaType,
    video,
    image,
    slug,
    callToAction,
    name,
    title,
    contentsSections1: cards,
    tooltipHumanData: {tooltipHumanData},
    tooltipInspirationalReality: {tooltipInspirationalReality},
    tooltipSimplexFuture: {tooltipSimplexFuture},
  } = data

  const newSlug = slugSwitch(slug)

  const renderHeading = color => (
    <div className={classes.headingWrapper}>
      <Heading isCentered>
        <Title
          variant="h2"
          type="largeTitle"
          hat={name}
          isCentered
          color={color}
        >
          {title}
        </Title>
        <Description
          animation={{animation: 'fade-up', duration: 1000}}
          isCentered
          type="description"
          color={color}
        >
          {shortDescription.shortDescription}
        </Description>
      </Heading>
    </div>
  )

  const renderDecorations = () => (
    <Decorations>
      <Decoration
        webSiteName="keyrus"
        color="grey"
        right={0}
        bottom={-60}
        animation={{direction: 'left', duration: 1000}}
      />
      <Decoration
        webSiteName="keyrus-management"
        color="grey"
        right={0}
        bottom={-60}
        animation={{direction: 'left', duration: 1000}}
      />
      <Decoration webSiteName="kls" svg="right" right={0} bottom={-60} />
    </Decorations>
  )

  return (
    <>
      <ParallaxProvider>
        <Section hasPaddingTop={false} hasPaddingBottom={false}>
          {renderHeading()}
        </Section>

        <ParallaxVideo
          classe={classes.banner}
          type="video/mp4"
          src=""
          loop
          autoPlay
          muted
          playsInline
          preload="none"
          parallaxMediaType={parallaxMediaType}
          video={video}
          image={image}
          paralaxClass={classes.parallaxClass}
        />

        <div className={`decorationWrapper ${classes.wrapper}`}>
          {renderDecorations()}
          <Section hasPaddingTop={false} hasPaddingBottom={false}>
            <div className={classes.subWrapper}>
              <div className={classes.textBlock}>
                <MiniPictures
                  isAnimated
                  cards={cards}
                  slug={slug}
                  tooltipHumanData={tooltipHumanData}
                  tooltipInspirationalReality={tooltipInspirationalReality}
                  tooltipSimplexFuture={tooltipSimplexFuture}
                />
                <div className={classes.buttonWrapper}>
                  <RoundButton
                    href={`../about-us/${newSlug}`}
                    arrow
                    isCentered
                    isNowrap
                  >
                    {callToAction}
                  </RoundButton>
                </div>
              </div>
            </div>
          </Section>
        </div>
      </ParallaxProvider>
    </>
  )
}

Manifesto.propTypes = manifestoPropTypes.isRequired

export default Manifesto
