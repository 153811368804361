import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({palette, breakpoints, spacing}) => ({
  sliderContainer: {
    '& .slide': {
      [breakpoints.down('sm')]: {
        height: 150,
      },
    },
    '& .slider': {
      [breakpoints.down('sm')]: {
        height: 200,
      },
    },
  },
  logo: {
    margin: spacing(0, 10),
    width: 200,
    height: 120,
    display: 'flex',
  },
  partnersContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  imageContainer: {
    width: 200,
    '& .gatsby-image-wrapper': {
      maxHeight: 120,
    },
    '& img': {
      width: '100%',
      height: '100%',
      maxHeight: 120,
      marginTop: 0,
      [breakpoints.down('sm')]: {
        maxHeight: 70,
      },
    },
    [breakpoints.down('sm')]: {
      width: 100,
    },
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 300,
  },
  titlePartner: {
    position: 'absolute',
    bottom: 50,
    [breakpoints.down('sm')]: {
      bottom: 0,
    },
    '& h2': {
      textDecoration: 'none',
      color: palette.text.primary,
    },
  },
}))

export default useStyles
